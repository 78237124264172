import React from "react";
import Header from "../Header";
import { domain, botUrl } from "../../config/ajax";
import axios from "axios";
import { tariffsPage } from "../../constants/event";
import {
  doPaymentMini,
  doPaymentStandard,
  doPaymentComfort,
  doPaymentVip,
} from "../../constants/event";
import { Item } from "./Item/Item";
import posed from "react-pose";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import Modal from "react-modal";
import Instruction from "../../assets/iphonexkaspi.png";
import { Pay } from "./Pay/Pay";

// const widget = new window.cp.CloudPayments();
// var result = window.cp.Checkout.prototype.createCryptogramPacket();
// if (result.success) {
//   // сформирована криптограмма
//   alert(result.packet);
// } else {
//   // найдены ошибки в введённых данных, объект `result.messages` формата:
//   // { name: "В имени держателя карты слишком много символов", cardNumber: "Неправильный номер карты" }
//   // где `name`, `cardNumber` соответствуют значениям атрибутов `<input ... data-cp="cardNumber">`
//   for (var msgName in result.messages) {
//     console.log(result.messages[msgName]);
//   }
// }
const Box = posed.div({
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
});

class Rate extends React.Component {
  constructor(props) {
    // console.log(result);
    super(props);
    this.setRate = this.setRate.bind(this);
    this.setCard = this.setCard.bind(this);
    // this.saveRate = this.saveRate.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.state = {
      rate: null,
      select: null,
      myTarif: "",
      has_subscription: null,
      modal: false,
      modal1: false,
      year: false,
      card: false,
      data: null,
    };
    this.rates = [
      {
        id: "standard",
        name: "Стандартный",
        text: [
          "Кол-во фильтров: 3",
          "Регион: только определенный город",
          "Марка: любая",
          "Расширенные опции: На 20 % дешевле рынка - активно",
        ],
        price: "1 499",
        month: "17 988",
        year: "12 591",
        save: "5 397",
      },
      {
        id: "comfort",
        name: "Комфортный",
        text: [
          "Кол-во фильтров: 5",
          "Регион: без ограничений",
          "Марка: без ограничений",
          "Расширенные опции: все",
        ],
        price: "1 999",
        month: "23 988",
        year: "16 791",
        save: "7 197",
      },
      {
        id: "vip",
        name: "VIP",
        text: [
          "Приоритет в получении новых уведомлений",
          "Увеличенная скорость поиска объявлений",
          "Кол-во фильтров: без ограничений",
          "Регион: без ограничений",
          "Марка: без ограничений",
          "Расширенные опции: все",
        ],
        price: "5 999",
        month: "71 988",
        year: "50 390",
        save: "21 598",
      },
    ];
    fetch(`${domain}/api/tarif?user_id=${sessionStorage.getItem("user_id")}`, {
      method: "GET",
      mode: "cors",
      credentials: "omit",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result.rate, 110);
          let rate = this.rates.find((value) => value.name === result.rate);
          if (rate) {
            this.setState({ rate: rate, select: rate, myTarif: rate.name });
          } else {
            this.setState({ rate: this.rates[2], select: this.rates[2] });
            if (result.rate === "trial") {
              this.setState({ myTarif: "Пробный" });
            } else {
              this.setState({ myTarif: "Отключен" });
            }
          }
          console.log(rate, 121);
          this.setState({ has_subscription: result.has_subscription });
        },
        (error) => {
          console.log(error);
        }
      );
  }

  componentDidMount() {
    const isTracked = sessionStorage.getItem("tariffsPage");
    const body = {
      ...tariffsPage,
      user_id: sessionStorage.getItem("user_id"),
    };
    if (!isTracked) {
      axios
        .post(`${botUrl}/api/ga/cabinet`, body)
        .then((result) => {
          sessionStorage.setItem("tariffsPage", true);
        })
        .catch((err) => console.log(err));
    }
    for (let item of this.rates) {
      if (item.id !== this.state.select) {
        this.setState({ rate: item.id });
        return false;
      }
    }
  }

  sendEvent(event) {
    const body = {
      ...event,
      user_id: sessionStorage.getItem("user_id"),
    };
    axios
      .post(`${botUrl}/api/ga/cabinet`, body)
      .catch((err) => console.log(err));
  }

  cancelSubscription() {
    fetch(
      `${domain}/api/tarif/cancel?user_id=${sessionStorage.getItem("user_id")}`,
      {
        method: "GET",
        mode: "cors",
        credentials: "omit",
      }
    );
    setTimeout(
      alert(
        "Ваша подписка отменена. В следующем месяце деньги не будут списаны."
      ),
      2000
    );
  }

  setRate(rate) {
    if (rate.id !== this.state.select) {
      this.setState({ rate: rate });
    }
  }

  saveRate() {
    if (this.state.rate?.name === this.state.myTarif) {
      return true;
    }
    const th = this;
    let data = {
      cloudPayments: {
        recurrent: {
          interval: "Month",
          period: this.state.year ? 12 : 1,
        },
      },
      tariff: this.state.rate.name,
      duration: this.state.year ? "year" : "month",
    }; //создание ежемесячной подписки
    this.setState({ data: data });
    if (this.state.rate.id === "mini") this.sendEvent(doPaymentMini);
    else if (this.state.rate.id === "standard")
      this.sendEvent(doPaymentStandard);
    else if (this.state.rate.id === "comfort") this.sendEvent(doPaymentComfort);
    else this.sendEvent(doPaymentVip);
    console.log(sessionStorage.getItem("user_id"));
    this.setState({
      payoptions: {
        // options
        // publicId: 'test_api_00000000000000000000001',
        Description: "Подписка на ежемесячный доступ к боту CARFASTBOT",
        Amount: th.state.year
          ? parseInt(th.state.rate.year.replace(" ", ""))
          : parseInt(th.state.rate.price.replace(" ", "")), //сумма
        Currency: "KZT", //валюта
        InvoiceId: sessionStorage.getItem("user_id"), //номер заказа  (необязательно)
        AccountId: "01" + sessionStorage.getItem("user_id"), //идентификатор плательщика (необязательно)
        JsonData: data,
      },
    });
    // widget.charge(
    //   {
    //     // options
    //     // publicId: 'test_api_00000000000000000000001',
    //     publicId: "pk_621ddb0b136db0dec1546c597193c",
    //     description: "Подписка на ежемесячный доступ к боту CARFASTBOT",
    //     amount: th.state.year
    //       ? parseInt(th.state.rate.year.replace(" ", ""))
    //       : parseInt(th.state.rate.price.replace(" ", "")), //сумма
    //     currency: "KZT", //валюта
    //     invoiceId: sessionStorage.getItem("user_id"), //номер заказа  (необязательно)
    //     accountId: "01" + sessionStorage.getItem("user_id"), //идентификатор плательщика (необязательно)
    //     data: data,
    //   },
    //   function (options) {
    //     // success
    //     th.setState({
    //       rate: th.state.rate,
    //       select: th.state.rate,
    //       myTarif: th.state.rate.name,
    //     });
    //     th.setRateInt();
    //   },
    //   function (reason, options) {
    //     console.log("TEST");
    //   }
    // );
  }

  setRateInt() {
    for (let item of this.rates) {
      if (item.id !== this.state.select) {
        this.setState({ rate: item });
        return false;
      }
    }
  }
  handleCloseModal() {
    this.setState({ modal: false, card: false });
    this.setState({ rate: null });
  }

  setCard(state) {
    this.setState({ card: true });
  }

  render() {
    return (
      <div className="w-full pt-16" style={{ backgroundColor: "#edebeb" }}>
        <Header logo={false} name="Тарифы" navigation={this.props} />
        <Modal
          isOpen={this.state.modal}
          className="w-full"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
          overlayClassName="Overlay  Overlay--before-close"
          closeTimeoutMS={200}
        >
          <div
            className="w-full bg-white px-6 pt-6 shadow-lg mt-20 text-left px-6 pb-3 z-20"
            style={{
              position: "absolute",
              height: "auto",
              flexDirection: "column",
            }}
          >
            <div style={{ textAlign: "center" }}>
              Выберите способ оплаты:
              <div
                className="flex items-center absolute right-0 top-0 font-bold text-gray-700 pr-2 "
                style={{
                  height: "1.40rem",
                  marginRight: "1em",
                  marginTop: "1em",
                }}
                onClick={this.handleCloseModal}
              >
                x
              </div>
              <div className="mt-10" style={{ justifyContent: "center" }}>
                <div
                  className="py-1 mx-10 mb-6 text-center text-white rounded shadow-lg bg-blue200o"
                  style={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    this.setState({ modal1: !this.state.modal1 });
                    this.saveRate();

                    // this.saveRate();
                    // this.setCard(true);
                    // this.saveRate();
                  }}
                >
                  Перевод Kaspi.kz
                  {/* Visa/Mastercard */}
                </div>
                {/* <a
                  style={{ fontSize: "0.8rem", textDecoration: "underline" }}
                  onClick={() => {
                    // this.setState({ modal1: !this.state.modal1 });
                    // this.saveRate();
                  }}
                >
                  Перевод Kaspi.kz
                </a> */}
              </div>
            </div>
            <Modal
              isOpen={this.state.modal1}
              className="w-full"
              onRequestClose={this.handleCloseModal}
              overlayClassName="Overlay Overlay--after-open Overlay--before-close "
            >
              {" "}
              <div
                className="bg-white mt-10 pb-20"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "80vh",
                  float: "left",
                  zIndex: "0",
                  position: "relative",
                  overflow: "scroll",
                }}
              >
                <div
                  className="font-bold mt-5 mb-2 mx-4"
                  style={{ textAlign: "center" }}
                >
                  Инструкция по оплате Kaspi переводом:
                </div>
                <div className="p-1" style={{ float: "left" }}>
                  1. Откройте приложение Kaspi.kz
                </div>
                <div className="p-1" style={{ float: "left" }}>
                  2. Зайдите в раздел переводы
                </div>
                <div className="p-1" style={{ float: "left" }}>
                  3. Выберите перевод Клиенту Kaspi
                </div>
                <div className="p-1" style={{ float: "left" }}>
                  4. Заполните форму, как показанно на нашем примере:
                </div>
                <div className="mx-10" style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      zIndex: "1",
                      top: "73%",
                      left: "45%",
                      textAlign: "center",
                      fontSize: "1.5vb",
                      opacity: this.state.modal ? "1" : "0",
                    }}
                  >
                    {this.state.year
                      ? this.state.rate?.year + " ₸"
                      : this.state.rate?.price + " ₸"}
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "1",
                      top: "93%",
                      left: "15%",
                      textAlign: "center",
                      fontSize: "1.5vb",
                    }}
                  >
                    {"01" + sessionStorage.getItem("user_id")}
                  </div>
                  <img
                    className="w-full"
                    src={Instruction}
                    style={{ height: "auto" }}
                  ></img>
                </div>
                <div
                  className="py-1 mb-6 mx-10 mt-4 text-center text-white rounded shadow-lg bg-blue200o"
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                  onClick={this.handleCloseModal}
                >
                  Спасибо, все понятно
                </div>
              </div>
            </Modal>
            <Modal
              isOpen={this.state.card}
              className="w-full"
              onRequestClose={this.handleCloseModal}
              overlayClassName="Overlay Overlay--after-open Overlay--before-close "
            >
              <Pay
                onClose={this.handleCloseModal}
                options={this.state.payoptions}
              />
            </Modal>
          </div>
          <div style={{ flex: "1 1 auto" }}></div>
        </Modal>
        <div className="w-full pb-4 ">
          <div
            className="w-full mb-4 text-m"
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="text-m pt-2 mr-2"
              style={{ height: "2rem", textAlign: "center" }}
            >
              Выберите тариф:
            </div>
            <div
              className="p-2"
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: "0.8rem",
              }}
            >
              <div className="mr-1">Месячный</div>
              <Toggle
                icons={false}
                onChange={() => {
                  this.setState({ year: !this.state.year });
                }}
              ></Toggle>
              <div className="ml-1">
                Годовой{" "}
                <span style={{ color: "green", fontWeight: "bold" }}>-30%</span>
              </div>
            </div>
          </div>
          <div className="w-full">
            {this.rates.map((item, index) => (
              <Item
                index={index}
                item={item}
                click={this.setRate}
                state={this.state}
                key={index}
                cancel={this.cancelSubscription}
                onClick={() => {
                  this.setState({ modal: !this.state.modal, modal1: false });
                }}
              />
            ))}
          </div>
        </div>
        <div className="w-full h-12"></div>
      </div>
    );
  }
}

export default Rate;
